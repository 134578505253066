import { Helmet } from "react-helmet";

type CustomTitleProps = {
  title: string;
};

export default function CustomTitle({ title }: CustomTitleProps) {
  return (
    <Helmet>
      <title>{title} - Orange Production</title>
    </Helmet>
  );
}
