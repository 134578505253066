import {
  getLogoDataUrl,
  getUserObservable,
  getNotifyObservable,

  // confirmation dialog
  // createConfirmationDialog,
  getConfirmationDialogObservable,
  TConfirmationDialogData,
  TBackdropData,
  // setBackdropVisibility,
  getBackdropObservable,
  getPageTitleObservable,
  getRequestCountObservable,
  getRequestErrorCountObservable,
  // setBackdropVisibility,
} from "@orangep/util";
import orange from "@mui/material/colors/orange";

import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { theme } from "./components/mui-theme/mui-theme";
import { OrangeIconButton, OrangeButton } from "./components/mui-theme";
import { navs } from "./navs";
import intersection from "lodash/intersection";
import { base_url } from "./helpers/common";
import { useEffect, useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
// import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ScrollTop from "./components/scroll-to-top";
import { SnackbarProvider, useSnackbar } from "notistack";
import ConfirmationDialog from "./components/confirmation-dialog";
import SimpleBackdrop from "./components/backdrop";
import CustomTitle from "./components/custom-title";
import { set } from "lodash";

function SnackbarWrapper(props) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const notify$ = getNotifyObservable().subscribe({
      next: (notify) => {
        if (!notify) {
          return;
        }
        enqueueSnackbar(notify.message, { variant: notify.variant });
      },
    });
    return () => {
      notify$.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.children;
}

export default function Root(props) {
  const { singleSpa } = props;
  // window.console.log("nav props", props);
  const [pathName, setPathname] = useState<string>(window.location.pathname);
  const [roles, setRoles] = useState<string[]>([]);
  const [userId, setUserId] = useState<string>("");
  const [userImage, setUserImage] = useState<string>("");
  const [pageTitle, setPageTitle] = useState<string>("");
  const [requestCounter, setRequestCounter] = useState<number>(0);
  const [requestErrorCounter, setRequestErrorCounter] = useState<number>(0);

  // utility state
  const [confirmationDialogData, setConfirmationDialogData] = useState<
    TConfirmationDialogData | undefined
  >(undefined);
  const [backdropData, setBackdropData] = useState<TBackdropData>({
    open: false,
    currentValue: 0,
    maxValue: 0,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const desktopSettingOpen = Boolean(anchorEl);
  const mobileSettingOpen = Boolean(mobileAnchorEl);

  const navigateFn = (url: string, e: React.MouseEvent) => {
    singleSpa.navigateToUrl(`${base_url}${url}`);
    e.preventDefault();
  };

  const popstateHandler = (e) => {
    if (e.singleSpa) {
      setPathname(window.location.pathname);
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", popstateHandler);
    const user$ = getUserObservable().subscribe({
      next: (user) => {
        if (!user) {
          setUserId("");
          setUserImage("");
          setRoles([]);
          return;
        }
        setRoles(user.userRoles.map((r) => r.roleCode));
        setUserId(user.id);
        setUserImage(user.lineUser.pictureUrl);
      },
    });

    const confirmationDialog$ = getConfirmationDialogObservable().subscribe({
      next: (data) => {
        setConfirmationDialogData(data);
      },
    });

    const backdrop$ = getBackdropObservable().subscribe({
      next: (data) => {
        setBackdropData(data);
      },
    });

    const pageTitle$ = getPageTitleObservable().subscribe({
      next: (title) => {
        setPageTitle(title);
      },
    });

    const requestCount$ = getRequestCountObservable().subscribe({
      next: (requestCount) => {
        setRequestCounter(requestCount);
      },
    });

    const requestErrorCount$ = getRequestErrorCountObservable().subscribe({
      next: (requestErrorCount) => {
        setRequestErrorCounter(requestErrorCount);
      },
    });

    return () => {
      window.removeEventListener("popstate", popstateHandler);
      user$.unsubscribe();
      confirmationDialog$.unsubscribe();
      backdrop$.unsubscribe();
      pageTitle$.unsubscribe();
      requestCount$.unsubscribe();
      requestErrorCount$.unsubscribe();
    };
  }, []);

  const toggleDesktopSetting = (e) => {
    if (desktopSettingOpen) {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(e.currentTarget);
  };

  const toggleMobileSetting = (e) => {
    if (mobileSettingOpen) {
      setMobileAnchorEl(null);
      return;
    }
    setMobileAnchorEl(e.currentTarget);
  };
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={10}
        autoHideDuration={2500}
        preventDuplicate
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
        <SnackbarWrapper>
          <CustomTitle title={pageTitle} />
          <Box sx={{ flexGrow: 1 }}>
            <AppBar color="default">
              <Toolbar // for mobile
                disableGutters
                sx={{
                  display: { xs: "flex", lg: "none" },
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <Box>
                  <img
                    src={getLogoDataUrl()}
                    width={80}
                    data-testid="logo"
                    alt="Orange Production Limited Partnership"
                  />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    right: 0,
                  }}
                >
                  {userId ? (
                    <>
                      <OrangeIconButton
                        size="large"
                        color="inherit"
                        data-testid="user-loggedin"
                        aria-label="menu"
                        aria-controls="mobile-menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleMobileSetting}
                      >
                        {/* <MenuOutlinedIcon /> */}
                        <img
                          src={userImage}
                          alt={"User"}
                          width={24}
                          height={24}
                          style={{ borderRadius: "50%" }}
                        />
                      </OrangeIconButton>
                      <Menu
                        id="mobile-menu-appbar"
                        anchorEl={mobileAnchorEl}
                        open={mobileSettingOpen}
                        onClose={() => setMobileAnchorEl(null)}
                      >
                        {navs.map((nav) => {
                          if (Array.isArray(nav.roles)) {
                            if (intersection(nav.roles, roles).length === 0) {
                              return null;
                            }
                          } else {
                            if (nav.roles && roles.length === 0) {
                              return null;
                            }
                          }
                          return (
                            <MenuItem
                              key={nav.url}
                              data-testid={nav.dataTest}
                              onClick={(e) => {
                                navigateFn(nav.url, e);
                                setMobileAnchorEl(null);
                              }}
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: orange[800],
                                  color: "white",
                                },
                              }}
                              // color={pathName === nav.url ? "status.danger" : "default"}
                              selected={pathName.startsWith(nav.url)}
                            >
                              {nav.icon}
                              <Typography sx={{ ml: 2 }}>
                                {nav.title}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                        <MenuItem
                          onClick={(e) => {
                            navigateFn("/auth/logout", e);
                            setMobileAnchorEl(null);
                          }}
                        >
                          <LogoutOutlinedIcon />
                          <Typography sx={{ ml: 2 }}>ออกจากระบบ</Typography>
                        </MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <OrangeIconButton
                      size="large"
                      onClick={(e) => {
                        navigateFn("/auth/line", e);
                        setMobileAnchorEl(null);
                      }}
                    >
                      <LoginOutlinedIcon />
                    </OrangeIconButton>
                  )}
                </Box>
              </Toolbar>
              <Toolbar // for desktop
                disableGutters
                sx={{
                  display: { xs: "none", lg: "flex" },
                  justifyContent: "space-around",
                }}
              >
                {/* Desktop */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "10px 0px",
                    ml: 2,
                  }}
                >
                  {/* Logo */}
                  <Box>
                    <img
                      src={getLogoDataUrl()}
                      width={80}
                      data-testid="logo"
                      alt="Orange Production Limited Partnership"
                    />
                  </Box>
                  <Box display="flex">
                    {navs.map((nav) => {
                      if (Array.isArray(nav.roles)) {
                        if (intersection(nav.roles, roles).length === 0) {
                          return null;
                        }
                      } else {
                        if (nav.roles && roles.length === 0) {
                          return null;
                        }
                      }

                      const ThisButton = pathName.startsWith(nav.url)
                        ? OrangeButton
                        : Button;
                      return (
                        <ThisButton
                          key={nav.url}
                          startIcon={nav.icon}
                          data-testid={nav.dataTest}
                          size="large"
                          color={
                            pathName.startsWith(nav.url) ? "primary" : "inherit"
                          }
                          onClick={(e) => navigateFn(nav.url, e)}
                        >
                          {nav.title}
                        </ThisButton>
                      );
                    })}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {userId ? (
                      <>
                        <OrangeIconButton
                          size="large"
                          data-testid="user-loggedin"
                          aria-controls="menu-user-appbar"
                          aria-haspopup="true"
                          onClick={toggleDesktopSetting}
                          color="inherit"
                        >
                          <img
                            src={userImage}
                            alt={"User"}
                            width={24}
                            height={24}
                            style={{ borderRadius: "50%" }}
                          />
                        </OrangeIconButton>
                        <Menu
                          id="menu-user-appbar"
                          anchorEl={anchorEl}
                          open={desktopSettingOpen}
                          onClose={() => setAnchorEl(null)}
                        >
                          <MenuItem
                            onClick={(e) => navigateFn("/auth/logout", e)}
                          >
                            ออกจากระบบ
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <OrangeIconButton
                        size="large"
                        aria-label="user-login"
                        aria-controls="menu-user-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={(e) => (window.location.href = "/auth/line")}
                      >
                        <LoginOutlinedIcon />
                      </OrangeIconButton>
                    )}
                  </Box>
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            data-testid="loading-progress"
            sx={{
              width: "100%",
              position: "fixed",
              left: 0,
              top: 0,
              zIndex: requestCounter > 0 ? 9999 : undefined,
              display: requestCounter > 0 ? "block" : "none",
            }}
          >
            <LinearProgress color="info" />
          </Box>
          <ScrollTop />
        </SnackbarWrapper>
      </SnackbarProvider>
      <ConfirmationDialog
        open={Boolean(confirmationDialogData)}
        {...confirmationDialogData}
      />
      <SimpleBackdrop
        open={backdropData.open}
        currentValue={backdropData.currentValue}
        maxValue={backdropData.maxValue}
      />
    </ThemeProvider>
  );
}
