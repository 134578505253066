import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type TBackdropProp = {
  open: boolean;
  currentValue: number;
  maxValue: number;
};

export default function SimpleBackdrop({
  open,
  currentValue,
  maxValue,
  ...props
}: TBackdropProp) {
  const progressValue = Math.round(100 * (currentValue / maxValue));
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        // position: "relative",
        // display: "inline-flex",
      }}
      open={open}
    >
      <CircularProgress
        color="primary"
        variant="determinate"
        value={progressValue}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${progressValue}%`}</Typography>
      </Box>
    </Backdrop>
  );
}
