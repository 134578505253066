import Box from "@mui/material/Box";
// import Fab from "@mui/material/Fab";
import Fade from "@mui/material/Fade";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
// import orange from "@mui/material/colors/orange";
import { OrangeFab } from "./mui-theme/fab";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  // children: React.ReactElement;
}

export default function ScrollTop(props: Props) {
  // const { window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // const anchor = (
    //   (event.target as HTMLDivElement).ownerDocument || document
    // ).querySelector("body");
    // console.log("anchor", anchor);
    // if (anchor) {
    //   anchor.scrollIntoView({
    //     block: "center",
    //   });
    // }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <OrangeFab size="small" aria-label="scroll back to top">
          <ArrowCircleUpIcon />
        </OrangeFab>
      </Box>
    </Fade>
  );
}
