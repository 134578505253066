import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";

export const OrangeButton = styled(Button)(({ theme }) => ({
  color: theme.status.danger,
  "&:hover": {
    color: "white",
    backgroundColor: theme.status.dangerHigh,
  },
}));
