import styled from "@mui/material/styles/styled";
import IconButton from "@mui/material/IconButton";

export const OrangeIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.status.danger,
  "&:hover": {
    color: "white",
    backgroundColor: theme.status.dangerHigh,
  },
}));
