import React from "react";
import { base_url } from "./helpers/common";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SummarizeIcon from "@mui/icons-material/Summarize";

type NavType = {
  title: React.ReactNode;
  icon: JSX.Element;
  dataTest: string;
  url: string;
  roles?: string[] | boolean;
};

export const navs: NavType[] = [
  {
    title: "งานทั้งหมด",
    icon: <HomeOutlinedIcon />,
    url: `${base_url}/job`,
    dataTest: "menu-job",
    roles: ["MAIN_MASTER", "MAIN_VIEW", "JOB_VIEW", "JOB_MASTER"],
  },
  {
    title: "ออเดอร์",
    icon: <AssignmentOutlinedIcon />,
    url: `${base_url}/order`,
    dataTest: "menu-order",
    roles: ["MAIN_MASTER", "MAIN_VIEW", "ORDER_VIEW", "ORDER_MASTER"],
  },
  {
    title: "TAG ส่งของ",
    icon: <TaskOutlinedIcon />,
    url: `${base_url}/transport-tag`,
    dataTest: "menu-transport-tag",
    roles: [
      "MAIN_MASTER",
      "MAIN_VIEW",
      "TRANSPORTTAG_VIEW",
      "TRANSPORTTAG_MASTER",
    ],
  },
  {
    title: "ใบส่งของชั่วคราว",
    icon: <TaskOutlinedIcon />,
    url: `${base_url}/delivery-note`,
    dataTest: "menu-delivery-note",
    roles: ["MAIN_MASTER", "TRANSPORTTAG_MASTER"],
  },
  {
    title: "ประวัติการส่งของ",
    icon: <LocalShippingIcon />,
    url: `${base_url}/transport-log`,
    dataTest: "menu-transport-log",
    roles: ["MAIN_MASTER", "MAIN_VIEW", "TRANSPORTTAG_VIEW"],
  },
  {
    title: "บันทึกเปิดบิล",
    icon: <DescriptionIcon />,
    url: `${base_url}/account/invoicing-order`,
    dataTest: "menu-invoicing-order",
    roles: ["MAIN_MASTER", "MAIN_VIEW", "INVOICE_MASTER", "INVOICE_VIEW"],
  },
  {
    title: "รายงาน",
    icon: <SummarizeIcon />,
    url: `${base_url}/report/order`,
    dataTest: "menu-order-report",
    roles: ["MAIN_MASTER", "MAIN_VIEW", "ORDER_VIEW", "ORDER_MASTER"],
  },
  {
    title: "ลูกค้า",
    icon: <PeopleOutlineIcon />,
    url: `${base_url}/customer`,
    dataTest: "menu-customer",
    roles: ["MAIN_MASTER", "MAIN_VIEW", "CUSTOMER_VIEW", "CUSTOMER_MASTER"],
  },
  {
    title: "ผู้ใช้งาน",
    icon: <PersonOutlinedIcon />,
    url: `${base_url}/user`,
    dataTest: "menu-user",
    roles: ["MAIN_MASTER", "MAIN_VIEW", "USER_VIEW", "USER_MASTER"],
  },
];
