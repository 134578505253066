import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DEFAULT_CONFIRM_TEXT, createConfirmationDialog } from "@orangep/util";

type ConfirmationDialogProps = DialogProps & {
  confirmationText: string;
  insensitiveConfirmText?: boolean;
  data?: any;
  onConfirm: (data: any) => void;
  onCancel: () => void;
};

export default function ConfirmationDialog({
  confirmationText,
  insensitiveConfirmText = false,
  data,
  onConfirm,
  onCancel,
  open,
  ...props
}: ConfirmationDialogProps) {
  const [typedText, setTypedText] = useState("");

  const [isDialogOpen, setIsDialogOpen] = useState(open);
  const _confirmationText = confirmationText || DEFAULT_CONFIRM_TEXT;

  useEffect(() => {
    if (!open) {
      setTypedText("");
    }
    setIsDialogOpen(open);
  }, [open]);

  const handleCancel = () => {
    onCancel();
    createConfirmationDialog(undefined);
  };

  const handleConfirm = () => {
    onConfirm(data);
    createConfirmationDialog(undefined);
  };

  const enableConfirmButton = insensitiveConfirmText
    ? typedText.toLowerCase() === _confirmationText.toLowerCase()
    : typedText === _confirmationText;

  return (
    <Dialog open={isDialogOpen} {...props}>
      <DialogTitle>กรุณายืนยัน</DialogTitle>
      <DialogContent>
        <DialogContentText>
          การยืนยันทำการ โปรดพิมพ์คำว่า {_confirmationText}{" "}
          ลงในช่องข้อความด้านล่าง
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label={`กรุณาพิมพ์ ${_confirmationText} เพื่อยืนยัน`}
          type="text"
          fullWidth
          variant="standard"
          inputProps={{
            "data-testid": "confirmation-text-field",
          }}
          value={typedText}
          onChange={(e) => setTypedText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          color="inherit"
          data-testid="confirmation-cancel-button"
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={!enableConfirmButton}
          color="primary"
          data-testid="confirmation-confirm-button"
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
}
