import styled from "@mui/material/styles/styled";
import Fab from "@mui/material/Fab";

export const OrangeFab = styled(Fab)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.status.danger,
  "&:hover": {
    color: "white",
    backgroundColor: theme.status.danger,
  },
}));
